@import "./variables";

a {
    text-decoration: unset !important;
}

@mixin fit-image() {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.disabled {
    pointer-events: none;
    opacity: 0.7; /* for "disabled" effect */
}

.logo-font {
    font-family: "Current";
    font-size: 25px;
    margin-top: 1%;
}

.text-black {
    color: black;
}

//Remove blue outline from input
.form-control:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@mixin gray-zoom-out() {
    //Gray out
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */

    //zoom reset to normal
    -webkit-transform: scale(1);
    transform: scale(1);
}

@mixin color-zoom-in() {
    //disable gray out effect
    -webkit-filter: grayscale(0);
    filter: none;
    cursor: pointer;

    //zoom in
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transform-origin: top left;
}

@mixin set-bg-color($color, $hover-color) {
    background-color: $color;

    &:hover {
        background-color: $hover-color;
    }
}

@mixin font-style-template($family, $size, $color) {
    font-family: $family;
    font-size: $size;
    color: $color;
    line-height: 1rem;
    margin-top: auto;
}

.header-font-25 {
    @include font-style-template("Current", 25px, $neutral2);

    &:hover {
        color: $neutral3-hover;
    }
}

@mixin font-size-template($fontsize, $color) {
    font-size: $fontsize;
    color: $color;
}

.small_text_style {
    @include font-size-template(8px, $neutral2);
}

.medium_text_style {
    @include font-size-template(25px, $neutral2);
}

.alert_message_style {
    @include font-size-template(10px, $error-text);
}

.middle-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
}

@mixin button-template($radius, $width, $bg-color, $color, $hover-color) {
    text-transform: uppercase;
    border-radius: $radius;
    width: $width;
    background-color: $bg-color !important;
    color: $color !important;
    &:hover {
        background-color: $hover-color !important;
    }
    @include font-style-template("Barlow-Regular", 15px, $neutral2);
    line-height: 1rem;
}

.btn-rounded {
    @include button-template(10em, 100%, $normal-button-color, $neutral2, $normal-button-color-hover);
}

.btn-rounded-dark {
    @include button-template(10em, 100%, $dark-button-color, $neutral2, $dark-button-color-hover);
}

.btn-host {
    @include button-template(10em, 100%, $yellow-button-color, $neutral2, $yellow-button-color-hover);
}

.btn-white-rounded {
    @include button-template(10em, 100%, $neutral3, $main2, $neutral3-hover);
    @include font-style-template("Barlow-Regular", 15px, $main2);
}

.btn-green-rounded {
    @include button-template(10em, 200px, $main2, $neutral2, $normal-button-color);
    margin-bottom: 10px;
}

.btn-dark-green-rounded {
    @include button-template(10em, 200px, $dark-button-color, $neutral2, $dark-button-color-hover);
    margin-bottom: 10px;
}

.btn-orange-rounded {
    @include button-template(10em, 200px, $action, $neutral2, $action-hover);
    margin-bottom: 10px;
}

.btn-host-rounded {
    @include button-template(10em, 200px, $yellow-button-color, $neutral2, $yellow-button-color-hover);
    margin-bottom: 10px;
}

.btn-dark-host-rounded {
    @include button-template(10em, 200px, $host-dark-color, $neutral2, $yellow-button-color-hover);
    margin-bottom: 10px;
}

.btn-blue-rounded {
    @include button-template(10em, 200px, $blue, $neutral2, #141d25);
    margin-bottom: 10px;
}

.input-rounded {
    border-radius: 10em;
    width: 100%;
    height: 40px;
}

.color_white {
    background-color: $neutral2;
}

@mixin icon-template() {
    margin-right: 5px;
    width: 30px;
    height: 30px;
}

.icon_style {
    @include icon-template();
}

.icon_style_white {
    @include icon-template();
    filter: invert(1);
    margin-bottom: 5px;
}

.icon_style_email {
    @include icon-template();
    filter: $email-icon-bg;
}

@mixin hr-template {
    margin-top: 0;
    margin-bottom: 2px;
    border-top-color: $neutral3-gray;
    border-width: 1px;
}

hr {
    @include hr-template();
}

h1 {
    @include font-style-template("Barlow-SemiBold", 20px, $neutral2);
}

h2 {
    @include font-style-template("Barlow-Regular", 15px, $neutral2);
}

h3 {
    @include font-style-template("Barlow-Regular", 13px, $neutral2);
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.link_style {
    text-transform: none;
    color: $neutral2;

    &:hover {
        color: $neutral3-hover;
    }
}

.left_align {
    text-align: left;
}

@mixin show-anime-template() {
    transition: opacity 0.6s linear;
    transform: translate(0);
    opacity: 1;
}

@mixin hide-anime-template() {
    opacity: 0;
}

::-webkit-input-placeholder {
    text-transform: uppercase;
    @include font-style-template("Barlow-Regular", 14px, $neutral3-gray);
}

.form-control {
    font-family: "Barlow-Regular";
    font-size: 14px;
    color: $black;
}

@mixin slider-template($active-color, $inactive-color) {
    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 25px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $inactive-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 21px;
        width: 21px;

        top: 2px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $active-color;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $active-color;
    }

    input:checked + .slider:before {
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 25px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

@mixin labelFieldTemplate($color: $host-dark-color) {
    div {
        hr {
            border-top-color: $color;
            width: 100%;
            margin-top: 4%;
            margin-bottom: 4%;
        }

        label {
            border-top-left-radius: 10em;
            border-bottom-left-radius: 10em;
            height: 35px;
            width: 32%;
            font-size: 12px;
            color: $neutral2;
            background: $color;
            text-transform: uppercase;
        }

        select,
        input {
            border-top-right-radius: 10em;
            border-bottom-right-radius: 10em;
            height: 35px;
            //width: 65%;
        }

        textarea {
            @include font-style-template("Barlow-Regular", 14px, $neutral3-gray);
            border-radius: 1em;
            width: 100%;
            background-color: white;
            box-shadow: unset;
            border: 0;
            color: black;
            padding: 8px;
            &:disabled {
                background-color: #e9ecef;
                opacity: 1;
            }
        }

        .host-image {
            background-size: cover;
            height: 120px;
            width: 32%;
        }
    }
}

@mixin toggleButtonTemplate($active-color, $inactive-color) {
    /**
Desktop widths
*/
    @media screen and (min-width: $screen-lg) {
        .toggle-button {
            @include slider-template($active-color, $inactive-color);

            label {
                background: none;
            }

            .switch {
                margin-left: 3%;
            }

            p {
                margin-left: 3%;
                color: white;
                width: 43%;
            }

            .btn-rounded {
                margin-left: 1%;
                @include button-template(10em, 21%, $host-dark-color, $neutral2, $action);
            }
        }
    }

    /**
  Phone specific styles
   */
    @media screen and (max-width: ($screen-md - 1)) {
        .toggle-button {
            flex-direction: column;

            .switch {
                margin-left: 1%;
            }

            p {
                margin-left: 1%;
                color: white;
                width: 80%;
            }

            .btn-rounded {
                margin-top: 5%;
                width: 55%;
            }
        }
    }
}

@mixin customScrollBarTemplate($barWidth, $barColor) {
    ::-webkit-scrollbar {
        width: $barWidth;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 10px solid $barColor;
    }
}
