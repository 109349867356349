@import "../../../../styles/common";

.register-with-container {
    width: 100%;
    div {
        margin-bottom: 10px;

        hr {
            margin-bottom: 30px;
        }

        .hr2 {
            margin-top: 30px;
            margin-bottom: 0px;
        }

        h1 {
            text-align: center;
            text-transform: uppercase;
        }

        p {
            @include font-style-template("Barlow-Regular", 12px, $neutral3);
            text-align: center;
            text-transform: uppercase;

            a {
                font-weight: bold;
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .btn-13 {
            font-size: 13px;
            text-align: left;
        }
    }
}
