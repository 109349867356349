@import "../../../styles/common";

.locations-container {
    max-width: 100vw;
    height: 90vh;

    select {
        background-image: url("../../../../public/assets/Icons/chevron-down.svg");
        background-size: 24px;
        background-position: calc(100% - 8px) center;
        background-repeat: no-repeat;
    }

    div {
        margin-bottom: 10px;
    }

    .col-6 {
        position: relative;
        margin-left: 4%;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        app-location-map {
            z-index: 1;
        }

        .coming-soon {
            z-index: 2;
            position: absolute;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 15em;
            width: 11em;
            height: 11em;
            margin-top: 10%;
            background-color: $blue;
            h3 {
                font-family: "Current";
                font-size: 1.2em;
                color: $action;
                margin-top: 20%;
                line-height: 1.2rem;
            }
            hr {
                height: 1px;
                width: 85%;
                color: white;
            }
            h4 {
                height: 40%;
                @include font-style-template("Barlow-Regular", 0.8em, $neutral2);
            }
        }
    }

    .col-4 {
        margin-top: 10%;

        h1 {
            font-family: "Current";
            font-size: 25px;
            line-height: 2rem;
            color: white;
        }

        hr {
            border-top-color: $neutral2;
            margin-bottom: 25px;
        }

        .hr2 {
            margin-top: 25px;
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .locations-container {
        display: flex;
        flex-direction: column-reverse;

        .col-6 {
            position: relative;
            margin-left: unset;
            flex: 1;
            max-width: 100%;

            .coming-soon {
                width: 5em;
                height: 5em;
                margin-top: 10%;

                h3 {
                    font-size: 0.5em;
                    margin-top: 20%;
                    line-height: 0.5rem;
                }

                hr {
                    margin-top: -15%;
                    width: 85%;
                }

                h4 {
                    font-size: 0.5em;
                    line-height: 0.5rem;
                }
            }
        }
        .col-4 {
            margin-top: unset;
            flex: 1;
            max-width: 100%;
        }
    }
}
