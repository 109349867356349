@import "../../../styles/common";

.explore-container {
    &.container-fluid {
        padding: 0;
        overflow-x: hidden;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .button-container {
        margin: 16px 0;
    }

    .swiper-container {
        .swiper-slide {
            img {
                width: 100%;
                height: 100%;
            }
        }

        .swiper-slide-active {
            @include color-zoom-in();
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            @include gray-zoom-out();
        }

        .swiper-button-prev,
        .swiper-button-next {
            color: white;
        }
    }

    .image-detail {
        overflow-y: hidden;
        min-height: 80vh;
        margin-top: -35px;
        background-color: $neutral3-hover;

        .col-6 {
            width: 60vw;
        }

        .col-4 {
            width: 40vw;
        }

        .icons-bar {
            @include font-style-template("Barlow-Regular", 16px, $neutral1);
            text-align: left;
            font-weight: lighter;
            line-height: 25px;
            margin-left: 5%;

            .desc-icons {
                height: 7vh;
                margin-top: 30px;

                img {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    filter: invert(100%) sepia(51%) saturate(410%) hue-rotate(291deg) brightness(81%) contrast(82%); //#bababa

                    &:hover {
                        filter: invert(64%) sepia(1%) saturate(0%) hue-rotate(341deg) brightness(89%) contrast(88%); //#8f8f8f
                    }

                    &:last-child {
                        margin-left: 1vw;
                    }
                }
            }

            h1 {
                @include font-style-template("Barlow-Bold", 20px, $neutral1);
                text-transform: uppercase;
            }

            .desc {
                height: 55vh;
                overflow-y: scroll;
            }

            hr {
                margin-top: 10px;
                margin-bottom: 15px;
            }

            .btn-rounded {
                @include button-template(10em, 200px, $normal-button-color, $neutral2, $normal-button-color-hover);
            }

            .btn-orange-rounded {
                margin-bottom: 0px;
            }
        }
    }

    .image-tiles {
        //height: 100vh;
        overflow-y: scroll;
        margin-top: -35px;
        background-color: #c6b69f;

        div {
            position: relative;
            //height: 70vh;
            text-align: center;

            &:hover {
                cursor: pointer;
            }

            .explore-message {
                background-color: #8e4a36;
                height: 50%;

                div {
                    top: 20%;
                    left: 10%;
                    width: 80%;

                    hr {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                    .info-head {
                        @include font-style-template("Barlow-Bold", 20px, $neutral2);
                        text-transform: uppercase;
                        text-align: left;
                        line-height: 25px;
                    }

                    .info-desc {
                        @include font-style-template("Barlow-Regular", 14px, $neutral2);
                        text-align: left;
                        line-height: 20px;
                        overflow-y: auto;
                    }
                }
            }

            .explore-list-first-element {
                height: 50%;
                background-size: cover;
            }

            img {
                width: 100%;
                height: 100%;
            }

            h2 {
                @include font-style-template("Barlow-Bold", 20px, $neutral2);
                text-transform: uppercase;
                text-align: left;
                line-height: 25px;
            }

            h3 {
                @include font-style-template("Barlow-Regular", 14px, $neutral2);
                text-transform: uppercase;
                font-weight: bold;
                text-align: left;
            }

            .explore-title {
                position: absolute;
                top: 10%;
                left: 10%;
                width: 50%;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            }
        }
    }

    @mixin grid-items($number, $suffix) {
        @for $i from 1 through $number {
            &.grid-#{$suffix}-#{$i} {
                grid-template-columns: repeat($i, 1fr);
            }
        }
    }

    .grid {
        display: grid;

        /*
    Desktop widths
    */
        @media screen and (min-width: $screen-lg) {
            @include grid-items(12, "lg");
        }

        /*
   Tablet widths
   */
        @media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
            @include grid-items(12, "md");
        }

        /*
    Phone widths
    */
        @media screen and (max-width: ($screen-md - 1)) {
            @include grid-items(12, "sm");
        }
    }

    .grid-item {
        background-size: cover;

        &::before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 0;
            padding-bottom: 99%;
        }
    }

    /*
   Tablet specific styles
  */
    @media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
        .image-tiles {
            div {
                filter: none;

                span {
                    opacity: 1;
                }
            }
        }
    }

    /**
  Phone specific styles
   */
    @media screen and (max-width: ($screen-md - 1)) {
        .image-tiles {
            .explore-info {
                display: contents;
            }

            div {
                filter: none;

                span {
                    opacity: 1;
                }
            }

            .explore-info {
                .explore-message {
                    height: unset;
                }

                .explore-list-first-element {
                    height: unset;

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 0;
                        padding-bottom: 100%;
                    }
                }
            }
        }
    }
}
