@import "../../../../styles/common";

.register-container {
    width: 100%;
    div {
        margin-bottom: 10px;
    }

    hr {
        margin-bottom: 30px;
    }

    .hr2 {
        margin-top: 30px;
        margin-bottom: 0px;
    }

    h1 {
        text-align: center;
        text-transform: uppercase;
    }

    h2 {
        text-align: center;
        text-transform: uppercase;
    }

    h3 {
        text-align: center;
        text-transform: none;
    }

    p {
        @include font-style-template("Barlow-Regular", 8.4px, $neutral3);
        text-align: left;
        text-transform: none;
    }

    .agree {
        margin-left: -20px;
    }
}
