@import "../../../styles/common";
@import "../../../styles/variables";

@mixin font-template($fontsize, $color) {
    font-size: $fontsize;
    color: $color;
}

@mixin align-template($margin-left, $margin-top) {
    margin-left: $margin-left;
    margin-top: $margin-top;
}

@mixin search-field-template() {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: $neutral3;
    &:hover,
    &:focus {
        background-color: $neutral3-hover;
    }
}

@mixin h-template() {
    color: $neutral1;
    margin: 0;
    padding-left: 20px;
    line-height: 30px;
    font-weight: lighter;
    width: 100%;
}

.box-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-row {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-font-43 {
    @include font-style-template("Current", 43px, $action);
    padding-bottom: 30px;
    margin-top: 10%;
}

.search-container {
    position: relative;
    width: 50vw;

    .activity-field {
        @include search-field-template();
        border-top-left-radius: 10em;
        border-bottom-left-radius: 10em;
        width: 40%;
        height: 50px;
        z-index: 10;
        text-transform: capitalize;

        input {
            background: unset;
            border: unset;
            margin: unset;
            padding: 0 0 0 16px;
            @include font-template(13px, unset);
        }

        &:hover {
            border-top-left-radius: 2em;
            border-bottom-left-radius: 0em;

            .dropdown-style {
                visibility: visible;
                @include show-anime-template();
            }
        }

        img {
            margin-right: 2px;
            width: 30px;
            height: 30px;
        }

        .dropdown-style {
            li {
                list-style-type: none;
                height: unset;
                text-transform: capitalize;
            }
        }
    }

    .location-field {
        @include search-field-template();
        width: 40%;
        height: 50px;
        z-index: 10;

        input {
            background: unset;
            border: unset;
            margin: unset;
            padding: 0 0 0 16px;
            @include font-template(13px, unset);
        }

        &:hover {
            .dropdown-style {
                visibility: visible;
                max-height: 250px;
                overflow-y: auto;
                @include show-anime-template();
            }
        }

        .dropdown-style {
            ul {
                li {
                    padding: 16px;
                    img {
                        margin-right: 2px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    .range-field {
        @include search-field-template();
        width: 20%;
        height: 50px;
        z-index: 10;
        margin-right: 30px;
        padding-right: 16px;

        &:hover {
            .dropdown-style {
                visibility: visible;
                @include show-anime-template();
            }
        }

        .dropdown-style {
            ul {
                li {
                    padding: 16px;
                }
            }
            .list-max-length {
                max-height: 240px;
            }
        }
    }

    .btn-search {
        position: absolute;
        z-index: 20;
        width: 55px;
        height: 55px;
        right: 0;
        top: -2px;
        background-color: $action;

        &:hover,
        &:focus {
            background-color: $action-hover;
        }

        img {
            filter: invert(1); //white
            margin-right: 3px;
            width: 40px;
            height: 40px;
        }
    }

    hr {
        margin-bottom: 0px;
        height: 1.5px;
        width: 90%;
    }

    h2 {
        @include h-template();
        text-transform: uppercase;
    }

    h3 {
        @include h-template();

        &:hover,
        &:focus {
            color: $action;
        }
    }

    .small-text {
        @include font-template(13px, $neutral3-gray);
    }

    .medium-text {
        @include font-template(15px, $neutral1);
    }

    .left-align {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 16px;
    }

    .field-title {
        height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }

    .selected-item {
        height: 50px;
        display: flex;
        justify-content: space-between;

        span {
            min-width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .dropdown-style {
        @include hide-anime-template();
        visibility: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 100%;
        text-align: left;
        background-color: $neutral3-hover;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        :focus {
            outline: none;
        }

        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            li {
                @include font-style-template("Barlow-Regular", 13px, $neutral1);
                list-style-type: none;
                height: unset;
                text-transform: capitalize;
                margin: 0;
                padding: 8px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                    color: $menu-item-hover;
                }
            }
        }
    }
    .list-max-length::-webkit-scrollbar {
        width: 6px;
    }

    .list-max-length::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $light-gray;
        border: none;
    }

    .list-max-length {
        width: 100%;
        max-height: 180px;
        overflow-x: auto;
    }
}

/*
   Tablet specific styles
  */
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .search-container {
        width: 70vw;
    }
}

/**
  Phone specific styles
   */
@media screen and (max-width: ($screen-md - 1)) {
    .search-container {
        width: 90vw;
    }

    .header-font-43 {
        font-size: 2.5rem;
        line-height: 2.3rem;
    }

    .activity-field,
    .location-field,
    .range-field {
        &:hover,
        &:focus {
            .dropdown-style {
                width: 55vw;
                visibility: visible;
                @include show-anime-template();
            }
        }
    }

    .range-field {
        width: 80px;

        .selected-item {
            span {
                white-space: break-spaces;
                text-align: center;
            }
        }

        .middle-align {
            padding: 0 8px 0 0;
        }
    }

    .small-text {
        font-size: 6px;
    }
}
