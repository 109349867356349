@import "../../../styles/common";

.hidden {
    visibility: hidden;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-gray;
    border: none;
    border: 5px solid white;
}

.location-list {
    list-style: none;
    margin: 0;
    padding: 8px;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);

    li {
        color: black;
        cursor: pointer;
        padding: 4px 0;
    }
}

.location-wrapper {
    position: relative;
    flex: 1;
}

.no-bottom-borders {
    border-radius: 24px 24px 0 0 !important;
    margin-bottom: 0 !important;
}
