@font-face {
    font-family: "Current";
    src: url("../../public/assets/Typografie/Current/Current-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Barlow-Bold";
    src: url("../../public/assets/Typografie/Barlow/Barlow-Bold.ttf") format("opentype");
}

@font-face {
    font-family: "Barlow-SemiBold";
    src: url("../../public/assets/Typografie/Barlow/Barlow-SemiBold.ttf") format("opentype");
}

@font-face {
    font-family: "Barlow-Regular";
    src: url("../../public/assets/Typografie/Barlow/Barlow-Regular.ttf") format("opentype");
}
