@import "../../../styles/common";

.save {
    margin-top: 8px;
}

.host-profile-container {
    padding-bottom: 24px;
    select {
        border-radius: 20px;
        background-image: url("../../../../public/assets/Icons/chevron-down.svg");
        background-size: 24px;
        background-position: calc(100% - 8px) center;
        background-repeat: no-repeat;
        text-transform: uppercase;

        option {
            &:first-of-type {
                opacity: 0.5;
            }
        }
    }

    .host-profile-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 1em;
        margin: 5% 10% 5% 10%;
        flex: 0 0 auto;
        box-shadow: -1px -1px 32px 12px rgba(0, 0, 0, 0.34);
    }

    .left-panel {
        position: relative;
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
        width: 30%;
        height: 100%;
        background: $neutral3;

        .host-overview {
            max-height: 100%;
            box-shadow: 2px -19px 39px -9px rgba(0, 0, 0, 0.34);
            text-transform: uppercase;
            font-family: "Barlow-Regular";
            color: $black;
            font-size: 14px;

            .checked {
                color: $host-bg-color;
                padding-right: 8px;
            }

            .unchecked {
                color: $gray;
                padding-right: 8px;
            }

            .host-name {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    margin: 0 0 16px 0;
                    padding: 0;
                }
                h4 {
                    margin: 0;
                    padding: 0;
                }
            }

            h2 {
                @include font-style-template("Barlow-Bold", 24px, $light-gray);
                padding-top: 2rem;
                padding-bottom: 2rem;
                text-align: center;
                height: 10%;
                text-align: center;
            }

            h3 {
                @include font-style-template("Barlow-Regular", 20px, $black);
                font-weight: bold;
            }

            h4 {
                @include font-style-template("Barlow-Regular", 16px, $black);
                line-height: 0rem;
                font-weight: lighter;
            }

            h5 {
                @include font-style-template("Barlow-Regular", 15px, $black);
                font-weight: bold;
                width: 40%;
            }

            h6 {
                @include font-style-template("Barlow-Regular", 13px, $black);
                text-transform: none;
            }

            .host-logo {
                margin-top: 2%;
                width: 70px;
                height: 70px;
                background-size: cover;
                border-radius: 50%;
            }

            .full-detail::-webkit-scrollbar {
                width: 6px;
            }

            .full-detail::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $light-gray;
                border: none;
            }

            .full-detail {
                width: 104%;
                max-height: 560px;
                overflow-y: scroll;
                overflow-x: hidden;

                hr {
                    margin-bottom: 3%;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                    .text {
                        width: 35%;
                        margin-top: 0;
                    }
                    .content {
                        width: 65%;
                    }
                }

                .host-image-list {
                    .host-image {
                        width: 80px;
                        height: 80px;
                        background-size: cover;
                        border-radius: 16px;
                        margin: 4px;
                    }
                }
            }

            .btn-host-rounded {
                margin-left: 10%;
                margin-top: 5%;
                width: 80%;
            }

            .row {
                margin-left: 1%;
                padding-bottom: 4%;
            }
        }
    }

    .middle-panel {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-transform: uppercase;
        background-color: $host-bg-color;
        //border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;

        .nav-tabs {
            height: 12%;
            border-bottom: none;
            background-color: $host-dark-color;
            flex: 0;
            // Apply border-radius over background
            //border-top-right-radius: 2em;

            .nav-item {
                width: 20%;

                .nav-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    @include font-style-template("Barlow-Bold", 24px, $neutral2);
                    line-height: unset;
                    padding: 1.5rem;
                    text-align: center;
                    border: 1px solid transparent;
                    border-top-left-radius: 0rem;
                    border-top-right-radius: 0rem;
                    box-shadow: inset -88px 145px 102px -131px rgba(0, 0, 0, 0.22);

                    &:hover {
                        background-color: $yellow-button-color;
                    }
                }
            }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: $host-bg-color;
        }

        .tab-content {
            overflow-y: auto;
            //background-color: $host-bg-color;

            .tab-pane {
                width: 100%;
                min-height: 98%;
                //background-color: $host-bg-color;
                border-bottom-right-radius: 1rem;

                h1 {
                    @include font-style-template("Barlow-Regular", 18px, $white);
                    font-weight: bold;
                }

                h2 {
                    @include font-style-template("Barlow-Regular", 14px, $host-dark-color);
                    font-weight: bold;
                }

                h3 {
                    @include font-style-template("Barlow-Regular", 20px, $neutral2);
                }

                .general,
                .activities,
                .locations,
                .reviews,
                .payment-info {
                    padding: 24px;
                    height: 100%;

                    hr {
                        background-color: #905e32;
                        opacity: 0.5;
                    }
                }

                .general {
                    @include labelFieldTemplate();
                    .general-info-form {
                        position: relative;
                        input,
                        textarea,
                        hr {
                            margin-bottom: 8px;

                            &.margin-top {
                                margin-top: 8px;
                            }
                        }

                        .switch-label {
                            font-size: 14px;
                            color: $host-dark-color;
                        }

                        h3 {
                            color: $host-dark-color;
                            font-size: 14px;
                            margin-top: 0;
                            padding-top: 0;
                        }

                        .btn-add-image {
                            margin-top: -10px;
                        }

                        .image-container {
                            &:first-of-type {
                                border-right: 1px solid $host-dark-color;
                                padding-right: 8px;
                                margin-right: 8px;
                            }
                        }
                        .image {
                            position: relative;
                            width: 80px;
                            height: 80px;
                            background-size: cover;
                            border-radius: 16px;
                            margin: 4px;

                            .image-action {
                                display: none;
                            }

                            &:hover {
                                .image-action {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    border-radius: 16px;
                                    position: absolute;
                                    inset: 0;
                                    background-image: unset;
                                    background-color: rgba(0, 0, 0, 0.5);
                                }
                            }
                        }
                        .last-column-header {
                            padding-right: 16px;
                        }
                        .flex-table {
                            display: flex;
                            flex-direction: column;
                            .flex-header {
                                color: $host-dark-color;
                                font-size: 14px;
                                font-weight: 300;
                                padding-bottom: 8px;
                            }
                            .flex-row {
                                color: $host-dark-color;
                                &:not(:last-of-type) {
                                    border-bottom: 1px dashed $host-dark-color;
                                }
                            }
                        }
                    }
                }

                .payment-info {
                    overflow-y: auto;
                    overflow-x: hidden;
                    @include labelFieldTemplate();
                    .payment-form {
                        position: relative;

                        input {
                            margin-bottom: 8px;
                        }

                        hr {
                            margin-top: 1%;
                        }

                        h2 {
                            color: $host-dark-color;
                            margin-bottom: 16px;
                        }
                    }
                }

                .activities {
                    position: relative;
                    .activities-list {
                        display: flex;
                        flex-direction: column;
                    }
                    .list {
                        margin: 8px 0;
                        width: 100%;
                        max-height: 500px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        //height: 400px;
                        //overflow-y: auto;
                        //overflow-x: hidden;
                    }
                    .btn-add-activity {
                        align-self: flex-end;
                        margin-top: 8px;
                    }
                }
                .locations {
                    position: relative;
                    .main-spot {
                        border-bottom: 1px solid rgba(144, 94, 50, 0.5);
                    }
                    select {
                        margin: 0.5rem 0;
                    }
                    .locations-list {
                        display: flex;
                        flex-direction: row;

                        .col-md-12,
                        .col-md-6:first-of-type {
                            padding-left: unset !important;
                        }

                        & > div:last-of-type {
                            padding-left: 16px;
                        }

                        .location-header__content {
                            color: #905e32;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: space-between;
                            & > div:last-of-type {
                                width: 45%;
                            }
                        }
                    }
                    .mapboxgl-map {
                        min-height: 400px;
                        margin-top: 8px;
                    }

                    .combined-form-picker {
                        width: 100%;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        border-radius: 20px;
                        background-color: white;
                        margin: 0.5rem 0;
                        justify-content: space-between;

                        .date-picker {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-size: 10px;
                        }

                        .form-control {
                            margin: 0;
                            border: 0;
                            border-radius: 0;
                            background-color: transparent;
                        }

                        .location-list {
                            margin-top: 40px;
                        }
                    }
                }
            }
        }
    }

    /*
     Desktop widths
  */
    @media screen and (min-width: $screen-lg) {
        .host-profile-content {
            max-width: 90%;
            height: 820px;
        }
    }

    /*
   Tablet specific styles
  */
    @media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
        .host-profile-content {
            margin: 0 5%;
        }
    }

    /**
  Phone specific styles
   */
    @media screen and (max-width: ($screen-md - 1)) {
        .host-profile-content {
            display: flow;
            flex-direction: column;
            margin: 0;
            height: 70vh;

            .left-panel::-webkit-scrollbar {
                display: none;
            }

            .left-panel {
                width: 100%;
                overflow: auto;
                border-top-left-radius: 1em;
                border-top-right-radius: 1em;
                border-bottom-left-radius: 0em;

                .host-overview {
                    .full-detail {
                        max-height: unset;
                        overflow-y: unset;
                        overflow-x: unset;
                    }
                }
            }

            .middle-panel {
                background-color: $host-dark-color;

                .nav-tabs {
                    height: 10%;

                    .nav-item {
                        .nav-link {
                            padding-top: 1rem;
                            padding-bottom: 0rem;
                            font-size: 10px;
                        }
                    }
                }

                .tab-content {
                    .tab-pane {
                        .general {
                            height: auto;
                        }

                        .activities {
                            height: 90vh;
                        }

                        .payment-info {
                            height: 90vh;
                        }
                    }
                }
            }
        }
    }
}
