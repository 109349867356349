@mixin base-button($background-color, $hover-color, $color) {
    border-radius: 10em;
    background-color: $background-color;
    color: $color;
    text-transform: uppercase;

    &:hover {
        background-color: $hover-color;
    }
}

.calendar-date-range {
    .rdrDefinedRangesWrapper {
        display: none;
    }
}

.dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 8px;
}

.become-host__container-dialog {
    width: auto !important;
    height: auto !important;
    position: unset !important;
    right: unset !important;
    top: unset !important;
}

.become-host__container,
.become-host__container-dialog {
    background-color: #ba8b48;
    width: calc(100vw - 350px);
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    right: 0;
    top: 0;

    h2 {
        font-size: 20px;
        color: white;
        text-transform: uppercase;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }

    h3 {
        font-size: 15px;
        color: #02322e;
        text-transform: uppercase;
        font-weight: 100;
        margin: 1rem 0;

        strong {
            font-weight: bold;
            margin-right: 1rem;
        }
    }

    .sidebar--content_form {
        margin: 5rem 7rem;
    }

    input,
    select {
        margin: 0.5rem 0;
        border-radius: 20px;

        &::placeholder {
            font-weight: 200;
            text-transform: uppercase;
        }
    }

    select {
        background-image: url("../../../../public/assets/Icons/chevron-down.svg");
        background-size: 24px;
        background-position: calc(100% - 8px) center;
        background-repeat: no-repeat;
        text-transform: uppercase;

        option {
            &:first-of-type {
                opacity: 0.5;
            }
        }
    }

    label {
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        text-transform: uppercase;
    }

    .input--search__wrapper {
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }

    .combined-form-picker {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: white;
        margin: 0.5rem 0;
        justify-content: space-between;

        .date-picker {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 10px;
        }

        .form-control {
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: transparent;
        }

        .location-list {
            margin-top: 40px;
        }
    }
    .agree {
        .label {
            margin: 0;
            font-size: 10px;
            line-height: 15px;
            color: white;
            text-transform: none;
        }
    }
    .buttons {
        display: flex;
        flex-direction: column;
        flex: 0;
        align-items: flex-start;

        button {
            margin: 0.5rem 0;
        }
    }

    .btn-next {
        @include base-button(#0f383e, #0a2529, #fff);
        width: 200px;
    }

    .btn-location {
        @include base-button(#045e5e, #0f383e, #fff);
        width: 100%;
        margin-bottom: 16px;
    }

    .btn-complete {
        @include base-button(#0f383e, #0a2529, #fff);
        width: 100%;
        margin-top: 16px;
    }

    .hidden {
        display: none;
    }

    .location-header {
        span {
            color: white;
            font-weight: 400;
        }
    }

    .location-header__content {
        color: #02322e;
        text-transform: uppercase;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        padding: 8px;

        div:last-of-type {
            width: 120px;
        }
    }

    .margin-bottom {
        border-bottom: 1px solid white;
        margin-bottom: 8px;
    }

    .half-width {
        max-width: 50%;
    }

    .location-list--container {
        margin: 16px;
        justify-content: flex-end;
    }

    .mapboxgl-map {
        min-height: 300px;
    }
}
.become-host__footer {
    margin: 1rem 150px;
    position: absolute;
    bottom: 0;
    z-index: 4;

    h1 {
        font-family: "Current";
        font-size: 35px;
        line-height: 3rem;
        color: white;
    }
}
