@import "~bootstrap/dist/css/bootstrap.min.css";
@import "styles/index.scss";
@import "styles/common";

#root {
    &::-webkit-scrollbar {
        display: none;
    }
}

body {
    margin: 0;
    font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::-webkit-scrollbar {
        display: none;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@mixin background-template($path) {
    background: url($path) no-repeat center fixed;
    background-size: cover;
    min-height: 100vh;
}

.app-background-black-white {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_Black_White.jpg");
}

.app-background-black {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_black.jpg");
}

.app-background-blue {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_blue.jpg");
}

.app-background-creme {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_creme.jpg");
}

.app-background-green {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_Green.jpg");
}

.app-background-lightgreen {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_lightGreen.jpg");
}

.app-background-orange {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_orange.jpg");
}

.app-background-yellow,
.host-profile,
.host-offers,
.host-bookings {
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_yellow.jpg");
}

.explore-color {
    background: $creme;
}

.activities-color {
    background: $red;
}

.locations-color {
    background: $accent1;
}

.bookings-color {
    background: $main2;
    @include background-template("../public/assets/design_files/background/Hoverflo_Desktop_Ill_COLOR_Green.jpg");
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .MuiDrawer-paperAnchorRight {
        width: 90% !important;
    }
}
