@import "../../../../styles/common";

.account-container {
    width: 100%;
    ul {
        list-style-type: none;

        a {
            @include font-style-template("Barlow-Regular", 14px, $neutral2);
            line-height: 1rem !important;
            cursor: pointer;

            &:hover {
                color: $menu-item-hover !important;
            }
        }
    }

    h1 {
        text-align: center;
    }

    h2 {
        font-weight: bold;
        text-transform: uppercase;
    }

    button {
        margin-bottom: 10px;
    }
}

/*
 Tablet specific styles
*/
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .account-container {
        h1 {
            display: none;
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .account-container {
        h1 {
            display: block;
        }
    }
}
