@import "../../../styles/common";
.bookings-container {
    padding-bottom: 24px;
    @include labelFieldTemplate();
    div {
        hr {
            border-top-color: $white;
        }

        label {
            background: $normal-button-color;
            padding-left: 16px;
            width: 130px !important;
        }

        input,
        select {
            padding-left: 4px;
            width: 100%;
            border-top-right-radius: 10em;
            border-bottom-right-radius: 10em;
        }
        select {
            background-image: url("../../../../public/assets/Icons/chevron-down.svg");
            background-size: 24px;
            background-position: calc(100% - 8px) center;
            background-repeat: no-repeat;
            text-transform: uppercase;
        }
    }

    .bookings-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $green-accent;
        border-radius: 1em;
        margin: -32px 10% 5% 10%;
        flex: 0 0 auto;
        box-shadow: -1px -1px 32px 12px rgba(0, 0, 0, 0.34);

        .left-panel {
            width: 30%;
            margin-top: 24px;

            .request-overview-header {
                height: 10%;
                display: flex;
                flex-direction: row;
                justify-content: center;

                h2 {
                    @include font-style-template("Barlow-Bold", 24px, $neutral2);
                    line-height: 3rem;
                }
            }

            .request-overview-info {
                display: flex;
                flex-direction: row;
                height: 90%;

                /* Hide scrollbar for Chrome, Safari and Opera */
                .activity-type-col::-webkit-scrollbar {
                    display: none;
                }

                .activity-type-col {
                    position: relative;
                    border-bottom-left-radius: 1em;
                    flex-direction: column;
                    height: 100%;
                    width: 43px;
                    overflow-y: scroll;

                    .selected_div {
                        animation: blinking-activity-type 5s infinite;
                    }

                    @keyframes blinking-activity-type {
                        0% {
                            background: $action;
                        }
                        25% {
                            background: $action-hover;
                        }
                        50% {
                            background: $action;
                        }
                        75% {
                            background: $action-hover;
                        }
                        100% {
                            background: $action;
                        }
                    }

                    .activity-name {
                        @include font-style-template("Barlow-Bold", 14px, $neutral2);
                        writing-mode: vertical-rl;
                        text-align: center;
                        text-transform: uppercase;
                        cursor: pointer;
                        opacity: 0.8;
                        height: 20%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: inset 0 0 1px $black;
                    }
                }

                /* Hide scrollbar for Chrome, Safari and Opera */
                .activity-info-col::-webkit-scrollbar {
                    display: none;
                }

                .activity-info-col {
                    flex-direction: column;
                    height: 100%;
                    width: 100%; //extent to full remaining width exclude 35px
                    background-color: $milky;
                    overflow-y: scroll;

                    .disable_all_content {
                        pointer-events: none;
                        opacity: 0.4;
                    }

                    .container {
                        width: auto;
                        height: 20%;
                        background-color: $milky;
                        border-bottom: 1px solid $light-gray;
                        cursor: pointer;

                        &:hover,
                        &.selected {
                            background-color: white;
                            .h6 {
                                color: $action-hover !important;
                            }
                        }

                        .request-status {
                            padding-top: 3%;
                            width: 13%;

                            .badge {
                                width: 15px;
                                height: 15px;
                                font-size: 9px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: white;
                                background-color: $action;
                                border-radius: 50%;
                                margin-left: 10%;
                            }

                            .icon_style_lock {
                                width: 18px;
                                height: 15px;
                                margin-left: 10%;
                            }
                        }

                        .request-details {
                            padding-top: 3%;
                            width: 100%;

                            .detail-section {
                                padding-top: 2%;
                                width: 65%;

                                .h6 {
                                    @include font-style-template("Barlow-Bold", 13px, $neutral1);
                                    line-height: 0rem;
                                }

                                .h7 {
                                    @include font-style-template("Barlow-Regular", 11px, $neutral1);
                                    padding-bottom: 20px;
                                }

                                .flex-row {
                                    padding-left: 6%;

                                    .h8 {
                                        @include font-style-template("Barlow-Bold", 10px, $neutral1);
                                    }

                                    .h9 {
                                        @include font-style-template("Barlow-Regular", 10px, $neutral1);
                                    }
                                }
                            }

                            .button-section {
                                width: 35%;

                                @mixin req-overview($color, $hover) {
                                    @include button-template(10em, 98%, $color, $neutral2, $hover);
                                    @include font-style-template("Barlow-Regular", 12px, $neutral2);
                                    margin-bottom: 5px;
                                    line-height: 0.5rem;
                                }

                                .btn-price-orange-rounded {
                                    @include req-overview($action, $action-hover);
                                }

                                .btn-price-gray-rounded {
                                    @include req-overview($light-gray, $light-gray);
                                }

                                .btn-status-white-rounded {
                                    @include button-template(10em, 98%, $neutral3-hover, $gray, $neutral3-hover);
                                    @include font-style-template("Barlow-Regular", 12px, $gray);
                                    margin-bottom: 5px;
                                    cursor: inherit;
                                }

                                .btn-status-gray-rounded {
                                    @include button-template(10em, 98%, $light-gray, $gray, $light-gray);
                                    @include font-style-template("Barlow-Regular", 12px, $gray);
                                    margin-bottom: 5px;
                                    cursor: inherit;
                                }

                                .btn-share-white-rounded {
                                    @include button-template(10em, 98%, $neutral3-hover, $main2, $neutral3-gray);
                                    @include font-style-template("Barlow-Regular", 8px, $gray);
                                    line-height: 0.5rem;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 8px 4px;

                                    .icon_style_share {
                                        transform: scaleX(-1);
                                        width: 10px;
                                        height: 10px;
                                        margin-right: 4px;
                                    }
                                }

                                .btn-remove-white-rounded {
                                    @include button-template(10em, 98%, $neutral3-hover, $main2, $neutral3-gray);
                                    @include font-style-template("Barlow-Regular", 8px, $gray);
                                    line-height: 0.5rem;
                                    margin-top: 5px;
                                }

                                .expire-text {
                                    @include font-style-template("Barlow-Regular", 9px, $action);
                                    width: 98%;
                                    text-align: center;
                                    margin-bottom: 10px;
                                }
                            }

                            .h6 {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .water {
                @include set-bg-color($blue-accent, #5d7785);
            }

            .earth {
                @include set-bg-color($green, $green-accent);
            }

            .alpine {
                @include set-bg-color($red, $red-accent);
            }

            .air {
                @include set-bg-color($creme, #b39e7f);
            }
        }

        .middle-panel {
            width: 45%;
            margin-top: 24px;

            text-transform: uppercase;

            .nav-tabs {
                margin-top: -6px;
                border-bottom: none;

                .nav-item {
                    width: 50%;

                    .nav-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        @include font-style-template("Barlow-Bold", 24px, $neutral2);
                        line-height: unset;
                        padding: 2rem;
                        text-align: center;
                        border: 1px solid transparent;
                        border-top-left-radius: 0rem;
                        border-top-right-radius: 0rem;

                        &:hover {
                            background-color: #196f6e;
                        }
                    }
                }
            }

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
                background-color: $green;
            }

            .tab-content {
                height: 90%;

                .tab-pane {
                    width: 100%;
                    height: 100%;
                }
            }

            .chat {
                background-color: $green;
                text-transform: none;

                .chat-request {
                    padding: 0 16px;
                    width: 100%;
                    height: 100%;

                    .date-picker {
                        margin-right: 8px !important;
                        label {
                            width: 146px !important;
                        }
                    }

                    input {
                        padding-left: 8px;
                    }

                    .date-picker__input {
                        width: 100%;
                        background-color: #fff;
                        border-top-right-radius: 10em;
                        border-bottom-right-radius: 10em;

                        .MuiInput-root {
                            &::before {
                                display: none;
                            }
                            &::after {
                                display: none;
                            }

                            &:hover {
                                &::before {
                                    display: none;
                                }
                            }

                            .MuiInput-input {
                                width: calc(100% - 56px);
                                margin: 0;
                                padding: 0 0 0 8px;
                                @include font-style-template("Barlow-Regular", 14px, $black);
                            }
                        }
                    }

                    .flex-row {
                        margin-bottom: 16px;
                        width: 100%;
                    }

                    hr {
                        margin-top: 1%;
                        margin-bottom: 3%;
                        background-color: #fff;
                    }

                    h1 {
                        padding-top: 5%;
                        @include font-style-template("Barlow-Regular", 20px, $neutral2);
                        margin-bottom: 16px;
                    }

                    h2 {
                        @include font-style-template("Barlow-Regular", 12px, $neutral2);
                        margin-bottom: 0.5rem;
                    }

                    h3 {
                        @include font-style-template("Barlow-Regular", 10px, $neutral2);
                        float: right;
                        text-transform: lowercase;
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                    }

                    .left-side {
                        margin-left: 0px;
                        margin-right: 0px;
                        flex: 1;
                    }

                    .right-side {
                        margin-left: 0px;
                        margin-right: 0px;
                        flex: 1;

                        input {
                            width: 60%;
                            float: right;
                        }
                    }

                    textarea {
                        @include font-style-template("Barlow-Regular", 14px, $black);
                        width: 100%;
                        margin-bottom: 4%;
                        padding: 2%;
                        outline: none;
                        border: none;
                    }

                    select {
                        text-transform: uppercase;
                        option {
                            text-transform: uppercase;
                        }
                    }

                    .buttons {
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        button {
                            width: 45%;
                        }
                    }
                }
            }

            .map {
                height: 100%; //required for mapbox
                background-color: $green;
            }
        }

        .right-panel {
            width: 25%;
            margin-top: 24px;
            text-transform: uppercase;
            font-family: "Barlow-Regular";
            color: $neutral2;
            font-size: 14px;

            .info-container {
                padding: 0 16px;
                height: 90%;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .flex-row {
                padding: 4px 0;

                .text {
                    width: 35%;
                    margin: 0;
                }
                .content {
                    width: 65%;
                }
            }

            .host-image-list {
                display: flex;
                flex-flow: wrap;
                justify-content: center;

                .host-image {
                    width: 80px;
                    height: 80px;
                    background-size: cover;
                    border-radius: 16px;
                    margin: 4px;
                }
            }

            .checked {
                color: orange;
                padding-right: 5px;
            }

            .unchecked {
                color: $green;
                padding-right: 5px;
            }

            .host-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 16px;

                h3 {
                    margin: 0 0 16px 0 !important;
                    padding: 0;
                }
                h4 {
                    margin: 0;
                    padding: 0;
                }
            }

            h2 {
                @include font-style-template("Barlow-Bold", 24px, $neutral2);
                padding-top: 2rem;
                padding-bottom: 1rem;
                text-align: center;
                height: 10%;
                text-align: center;
            }

            h3 {
                @include font-style-template("Barlow-Regular", 20px, $neutral2);
                font-weight: bold;
            }

            h4 {
                @include font-style-template("Barlow-Regular", 16px, $neutral2);
                font-weight: lighter;
            }

            h5 {
                @include font-style-template("Barlow-Regular", 15px, $neutral2);
                font-weight: bold;
                width: 40%;
            }

            .host-logo {
                width: 70px;
                height: 70px;
                background-size: cover;
                border-radius: 50%;
            }

            .btn-rounded {
                @include button-template(10em, 100%, $green, $neutral2, $normal-button-color-hover);
            }
        }
    }
}

/*
   Desktop widths
*/
@media screen and (min-width: $screen-lg) {
    .bookings-container {
        .bookings-content {
            max-width: 90%;
            height: 820px;
        }
    }
}

/*
 Tablet specific styles
*/
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .bookings-container {
        .bookings-content {
            margin: 0 5%;
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .bookings-container {
        .bookings-content {
            display: flow;
            margin: 0;
            background-color: $green-accent;

            .left-panel {
                height: 70vh;
                width: 100%;

                .request-overview-info {
                    .activity-type-col {
                        .activity-name {
                            height: 23vh;
                        }
                    }

                    .activity-info-col {
                        .container {
                            height: 23vh;
                        }
                    }
                }
            }

            .middle-panel {
                height: 90vh;
                width: 100%;

                .chat {
                    .chat-request {
                        .left-side,
                        .right-side {
                            width: 100%;

                            input {
                                width: 70%;
                            }
                        }

                        .buttons {
                            width: 100%;
                        }
                    }
                }
            }

            .right-panel {
                height: 100vh;
                width: 100%;
                margin-top: 0%;
            }
        }
    }
}
