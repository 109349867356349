.dropdown {
    background-image: url("../../../../public/assets/Icons/chevron-down.svg");
    background-size: 24px;
    background-position: calc(100% - 4px) center;
    background-repeat: no-repeat;
    text-transform: uppercase;

    .rounded {
        border-radius: 20px;
    }

    option {
        &:first-of-type {
            opacity: 0.5;
        }
    }
}
