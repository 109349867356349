@import "../../../styles/common";
.host-bookings-container {
    margin-top: 6%;
    padding-bottom: 24px;
    select {
        padding-left: 8px;
        width: 100%;
        border-top-right-radius: 10em;
        border-bottom-right-radius: 10em;
    }

    .host-bookings-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $host-dark-color;
        border-radius: 1em;
        margin: -32px 10% 5% 10%;
        flex: 0 0 auto;
        box-shadow: -1px -1px 32px 12px rgba(0, 0, 0, 0.34);

        .left-panel {
            width: 30%;
            margin-top: 20px;

            .request-overview-header {
                height: 10%;
                display: flex;
                flex-direction: row;
                justify-content: center;

                h2 {
                    @include font-style-template("Barlow-Bold", 24px, $neutral2);
                    line-height: 3rem;
                    margin: 8px 0 0 0;
                }
            }

            .request-overview-info {
                display: flex;
                flex-direction: row;
                height: 90%;

                /* Hide scrollbar for Chrome, Safari and Opera */
                .activity-type-col::-webkit-scrollbar {
                    display: none;
                }

                .status-type-col {
                    position: relative;
                    border-bottom-left-radius: 1em;
                    flex-direction: column;
                    height: 100%;
                    width: 43px;
                    overflow-y: scroll;

                    .selected_div {
                        animation: blinking-activity-type 5s infinite;
                    }

                    @keyframes blinking-activity-type {
                        0% {
                            background: $action;
                        }
                        25% {
                            background: $action-hover;
                        }
                        50% {
                            background: $action;
                        }
                        75% {
                            background: $action-hover;
                        }
                        100% {
                            background: $action;
                        }
                    }

                    .status-name {
                        @include font-style-template("Barlow-Bold", 14px, $neutral2);
                        writing-mode: vertical-rl;
                        text-align: center;
                        text-transform: uppercase;
                        cursor: pointer;
                        opacity: 0.8;
                        height: 20%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: inset 0 0 1px $black;
                    }
                }

                /* Hide scrollbar for Chrome, Safari and Opera */
                .request-info-col::-webkit-scrollbar {
                    display: none;
                }

                .request-info-col {
                    flex-direction: column;
                    height: 100%;
                    width: 100%; //extent to full remaining width exclude 35px
                    background-color: $milky;
                    overflow-y: scroll;

                    .disable_all_content {
                        pointer-events: none;
                        opacity: 0.4;
                    }

                    .container {
                        width: auto;
                        height: 20%;
                        background-color: $milky;
                        border-bottom: 1px solid $light-gray;
                        cursor: pointer;

                        &:hover,
                        &.selected {
                            background-color: white;
                            .h6 {
                                color: $action-hover !important;
                            }
                        }

                        .request-status {
                            padding-top: 3%;
                            width: 13%;

                            .badge {
                                width: 15px;
                                height: 15px;
                                font-size: 9px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: white;
                                background-color: $action;
                                border-radius: 50%;
                                margin-left: 10%;
                            }

                            .icon_style_lock {
                                width: 18px;
                                height: 15px;
                                margin-left: 10%;
                            }
                        }

                        .request-details {
                            padding-top: 3%;
                            width: 100%;

                            .detail-section {
                                padding-top: 2%;
                                width: 65%;

                                .h6 {
                                    @include font-style-template("Barlow-Bold", 13px, $neutral1);
                                    line-height: 0rem;
                                }

                                .h7 {
                                    @include font-style-template("Barlow-Regular", 11px, $neutral1);
                                    padding-bottom: 20px;
                                }

                                .flex-row {
                                    padding-left: 6%;

                                    .h8 {
                                        @include font-style-template("Barlow-Bold", 10px, $neutral1);
                                    }

                                    .h9 {
                                        @include font-style-template("Barlow-Regular", 10px, $neutral1);
                                    }
                                }
                            }

                            .button-section {
                                width: 35%;

                                @mixin req-overview($color, $hover) {
                                    @include button-template(10em, 98%, $color, $neutral2, $hover);
                                    @include font-style-template("Barlow-Regular", 12px, $neutral2);
                                    margin-bottom: 5px;
                                    line-height: 0.5rem;
                                }

                                .btn-price-orange-rounded {
                                    @include req-overview($action, $action-hover);
                                }

                                .btn-price-gray-rounded {
                                    @include req-overview($light-gray, $light-gray);
                                }

                                .btn-status-white-rounded {
                                    @include button-template(10em, 98%, $neutral3-hover, $gray, $neutral3-hover);
                                    @include font-style-template("Barlow-Regular", 12px, $gray);
                                    margin-bottom: 5px;
                                    cursor: inherit;
                                }

                                .btn-status-gray-rounded {
                                    @include button-template(10em, 98%, $light-gray, $gray, $light-gray);
                                    @include font-style-template("Barlow-Regular", 12px, $gray);
                                    margin-bottom: 5px;
                                    cursor: inherit;
                                }

                                .btn-remove-white-rounded {
                                    @include button-template(10em, 98%, $neutral3-hover, $main2, $neutral3-gray);
                                    @include font-style-template("Barlow-Regular", 8px, $gray);
                                    line-height: 0.5rem;
                                    margin-top: 5px;
                                }

                                .expire-text {
                                    @include font-style-template("Barlow-Regular", 9px, $action);
                                    width: 98%;
                                    text-align: center;
                                    margin-bottom: 10px;
                                }
                            }

                            .h6 {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .water {
                @include set-bg-color($blue-accent, #5d7785);
            }

            .earth {
                @include set-bg-color($green, $green-accent);
            }

            .alpine {
                @include set-bg-color($red, $red-accent);
            }

            .air {
                @include set-bg-color($creme, #b39e7f);
            }

            .host {
                @include set-bg-color($host-bg-color, $host-bg-hover-color);
            }
        }

        .middle-panel {
            width: 70%;
            //margin-top: 24px;

            text-transform: uppercase;

            .nav-tabs {
                //margin-top: -6px;
                height: 12%;
                border-bottom: none;

                .nav-item {
                    width: 25%;

                    .nav-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        @include font-style-template("Barlow-Bold", 24px, $neutral2);
                        line-height: unset;
                        padding: 2rem;
                        text-align: center;
                        border: 1px solid transparent;
                        border-top-left-radius: 0rem;
                        border-top-right-radius: 0rem;

                        -webkit-box-shadow: inset -88px 145px 102px -131px rgba(0, 0, 0, 0.22);
                        -moz-box-shadow: inset -88px 145px 102px -131px rgba(0, 0, 0, 0.22);
                        box-shadow: inset -88px 145px 102px -131px rgba(0, 0, 0, 0.22);

                        &:hover {
                            background-color: $yellow-button-color;
                        }
                    }
                }
            }

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
                background-color: $host-bg-color;
            }

            .tab-content {
                height: 90%;

                .tab-pane {
                    width: 100%;
                    height: 100%;
                }
            }

            .chat {
                height: calc(98% - 2px) !important;
                background-color: $host-bg-color;
            }

            .offer {
                height: calc(98% - 2px) !important;
                padding: 16px;
                background-color: $host-bg-color;
                overflow-y: auto;

                .choose-offer {
                    .new {
                        .body {
                            .body-content {
                                padding: 8px;
                                .new-offer {
                                    background-color: white;
                                    text-align: center;
                                    height: 100%;
                                    padding: 24px;

                                    &:hover {
                                        cursor: pointer;
                                        background-color: $host-bg-color;
                                        img {
                                            filter: invert(1);
                                        }
                                        h1,
                                        h2 {
                                            color: white;
                                        }
                                    }

                                    img {
                                        width: 150px;
                                        height: 150px;
                                        filter: $offer-template-icon;
                                    }

                                    h1,
                                    h2 {
                                        color: black;
                                    }

                                    h1 {
                                        margin-top: 16px;
                                    }

                                    h2 {
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }

                    .existing {
                        .body {
                            .body-content {
                                /* Hide scrollbar for Chrome, Safari and Opera */
                                .offer-template-list::-webkit-scrollbar {
                                    display: none;
                                }

                                .offer-template-list {
                                    position: relative;
                                    height: 98%;
                                    width: 100%;
                                    overflow-y: scroll;
                                    .offer-template {
                                        margin: 8px;
                                        display: inline-block;
                                        width: calc(33% - 16px);
                                        text-align: center;
                                        background: white;
                                        padding: 8px;

                                        &:hover {
                                            cursor: pointer;
                                            background: $host-bg-color;

                                            img {
                                                filter: invert(1);
                                            }

                                            h1,
                                            h3 {
                                                color: white;
                                            }
                                        }

                                        img {
                                            width: 80px;
                                            filter: $offer-template-icon;
                                            margin: 8px 0;
                                        }

                                        h1 {
                                            @include font-style-template("Barlow-Regular", 16px, $neutral1);
                                            text-transform: uppercase;
                                            font-weight: bolder;
                                            margin-top: unset;
                                            margin-bottom: 2px;
                                        }

                                        h3 {
                                            @include font-style-template("Barlow-Regular", 14px, $neutral1);
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new {
                        width: 40%;
                    }
                    .existing {
                        width: 60%;
                    }

                    .new,
                    .existing {
                        //min-height: 50vh;
                        border-radius: 1em;

                        .title {
                            border-top-left-radius: 1em;
                            border-top-right-radius: 1em;
                            background-color: $host-dark-color;
                            @include font-style-template("Barlow-Bold", 20px, $neutral2);
                            padding: 16px;
                            text-align: center;
                        }
                        .body {
                            border-bottom-left-radius: 1em;
                            border-bottom-right-radius: 1em;
                            background-color: $milky;
                        }
                    }
                }

                .make-offer {
                    height: 100%;
                    @include labelFieldTemplate();

                    h1 {
                        @include font-style-template("Barlow-Regular", 18px, $white);
                        font-weight: bold;
                    }

                    h2 {
                        @include font-style-template("Barlow-Regular", 14px, $host-dark-color);
                        font-weight: bold;
                    }

                    h3 {
                        @include font-style-template("Barlow-Regular", 13px, $neutral2);
                        text-align: right;
                    }

                    input,
                    textarea,
                    select,
                    hr {
                        margin-top: 0;
                        margin-bottom: 8px;

                        &.margin-top {
                            margin-top: 8px;
                        }
                    }

                    hr {
                        background-color: #905e32;
                        opacity: 0.5;
                    }

                    .title {
                        color: white;
                        h2 {
                            color: $white;
                            padding-left: 8px;
                        }
                        h3 {
                            text-transform: none;
                            padding-left: 8px;
                        }
                    }
                    .btn-back {
                        margin-right: 8px;
                    }

                    .check {
                        display: flex;
                        align-items: center;
                        label {
                            background: unset;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .agreements {
                        > div {
                            padding: 8px;
                        }
                        textarea {
                            height: 300px;
                        }
                        .agreement {
                            width: 100%;
                            height: 300px;
                            border-radius: 1em;
                            background-color: $milky;
                            color: gray;
                            overflow-y: auto;
                            p {
                                text-transform: none;
                                padding: 8px;
                                text-align: left;
                                @include font-style-template("Barlow-Regular", 12px, $gray);
                            }
                            hr {
                                background-color: $gray;
                            }
                            h2 {
                                color: $gray;
                            }
                        }
                    }
                    .overview {
                        background-color: #cead7e;
                        color: white;
                        border-radius: 1em;
                        padding: 8px;
                        height: 400px;
                        .flex-row {
                            margin: 8px 0;
                        }

                        hr {
                            background-color: $neutral2;
                            margin: 4px 0;
                        }

                        h1 {
                            @include font-style-template("Barlow-Regular", 12px, $neutral2);
                            margin: 0;
                        }
                        h2 {
                            @include font-style-template("Barlow-Regular", 12px, $neutral2);
                            text-transform: unset;
                            margin: 0;
                        }
                    }

                    .tc {
                        @include font-style-template("Barlow-Regular", 13px, $neutral2);
                        text-transform: none;
                        padding: 8px;
                        height: 400px;

                        .tc-details::-webkit-scrollbar {
                            width: 6px;
                        }

                        .tc-details::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: $light-gray;
                            border: none;
                        }

                        .tc-details {
                            max-height: 80%;
                            overflow-y: scroll;
                            overflow-x: hidden;
                        }

                        div {
                            margin-bottom: 8px;
                        }

                        .btn-host-rounded {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/*
   Desktop widths
*/
@media screen and (min-width: $screen-lg) {
    .host-bookings-container {
        .host-bookings-content {
            max-width: 90%;
            height: 820px;
        }
    }
}

/*
 Tablet specific styles
*/
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .host-bookings-container {
        .host-bookings-content {
            margin: 0 5%;
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .host-bookings-container {
        .host-bookings-content {
            display: flow;
            margin: 0;
            background-color: $green-accent;

            .left-panel {
                height: 70vh;
                width: 100%;

                .request-overview-info {
                    .status-type-col {
                        .status-name {
                            height: 23vh;
                        }
                    }

                    .request-info-col {
                        .container {
                            height: 23vh;
                        }
                    }
                }
            }

            .middle-panel {
                height: 70vh;
                width: 100%;
                background-color: $host-dark-color;

                .nav-tabs {
                    .nav-item {
                        width: 50%;
                    }
                }
            }
        }
    }
}
