@import "../../../styles/common";

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    color: white;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        color: $action;
    }
}

.sidebar-container {
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
}

.sidebar-container__account-overview {
    background-color: $sidebar-color1;
}

.sidebar-container__login {
    background-color: $sidebar-color2;
}

.sidebar-container__forgot-password {
    background-color: $sidebar-color2;
}

.sidebar-container__register {
    background-color: $sidebar-color2;
}

.sidebar-container__register-with {
    background-color: $sidebar-color2;
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .sidebar-container {
        padding: 2rem;
        min-height: unset;
    }
}
