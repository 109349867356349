//WATER SPORTS
$blue: #253747;
$blue-accent: #688494;
//EARTH SPORTS (LOW - FOREST)
$green: #005f5e;
$green-accent: #003532;
//EARTH SPORTS (HIGH -ALPINE)
$red: #8e4a36;
$red-accent: #4e2520;
//AIR SPORTS
$creme: #c6b69f;
$creme-accent: #e5dcce;
//HOST
$yellow: #bc8a48;
$yellow-accent: #905e32;

//NEUTRAL COLORS
$black: #000000;
$gray: #4a4b4c;
$light-gray: #b4b7ba;
$medium-gray: #717274;
$milky: #ededed;
$white: #ffffff;

//ACTION
$action: #ee7315;
$action-hover: #c25c0e;

//remove below and refer above
$main1: #0b3752;
$main2: #005f5e;
$main3: #8d4936;
$main4: #c6b69f;

$accent1: #678494;
$accent2: #a4ad9d;
$accent3: #bd8a49;

$neutral1: #000000;
$neutral2: #ffffff;
$neutral3: #ededed;
$neutral3-hover: #e0e0e0;
$neutral3-light: #fafafa;
$neutral3-gray: #bababa;

$normal-button-color: #0f383e;
$normal-button-color-hover: #0a2529;
$dark-button-color: #0a262a;
$dark-button-color-hover: #051315;
$yellow-button-color: #ae7d3f;
$yellow-button-color-hover: #896231;
$host-dark-color: #905e32;
$host-bg-color: #ba8b48;
$host-bg-hover-color: #896231;

$error-text: red;
$menu-item-hover: $action;

//create filter from color code https://codepen.io/sosuke/pen/Pjoqqp
$menu-item-icon-hover: invert(45%) sepia(96%) saturate(1518%) hue-rotate(357deg) brightness(100%) contrast(87%); //color of $action
$email-icon-bg: invert(21%) sepia(46%) saturate(707%) hue-rotate(140deg) brightness(97%) contrast(91%); //color:#144b53
$chat-icon-bg: invert(79%) sepia(7%) saturate(20%) hue-rotate(350deg) brightness(96%) contrast(84%); // color: #bababa
$offer-template-icon: invert(48%) sepia(84%) saturate(307%) hue-rotate(354deg) brightness(92%) contrast(90%);
/**
Screen sizes
 */
$screen-md: 768px !default;
$screen-lg: 992px !default;

$sidebar-color1: #8d4936;
$sidebar-color2: #144b53;
