@import "../../../../styles/common";

.login-container {
    width: 100%;
    div {
        margin-bottom: 10px;

        .social {
            cursor: pointer;
        }

        hr {
            margin-bottom: 30px;
        }

        .hr2 {
            margin-top: 30px;
            margin-bottom: 0px;
        }

        h1 {
            text-align: center;
            text-transform: uppercase;
        }

        h3 {
            text-align: center;
            margin-left: 8px;
            text-transform: uppercase;
        }

        p {
            @include font-style-template("Barlow-Regular", 9px, $neutral3);
            text-align: center;
            text-transform: uppercase;
        }
    }
}
