@import "../../../styles/common";
.web-view {
    position: relative;
    display: block;

    .navbar {
        padding: unset;
    }

    .navbar-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100px;
        z-index: 2;
        img {
            cursor: pointer;
            outline: none;
        }
    }

    .navbar-collapse {
        position: absolute;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
        flex-direction: row;
        justify-content: center;
        z-index: 1;

        .navbar-nav {
            display: inline-flex;
            .nav-item {
                a {
                    display: block;
                }
            }
        }

        ul {
            align-items: center;

            .active > a {
                color: $action;
            }

            li {
                text-transform: uppercase;

                a {
                    color: white;

                    &:hover {
                        color: $action;
                    }
                }
            }
        }
    }

    .user-name {
        margin-right: 3rem;
        color: white;
        text-align: right;
        text-transform: uppercase;
    }

    .sidebar-button {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;
        z-index: 5;
    }
}

.mobile-view {
    display: none;
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .web-view {
        .navbar {
            .navbar-header {
                a {
                    display: none;
                }
                img {
                    display: block;
                }
                .user-name {
                    margin-top: 3rem;
                }
            }

            .navbar-collapse {
                min-width: 70vw;
                max-width: 70vw;
                .navbar-nav {
                    .nav-item {
                        a {
                            display: none;
                        }
                        img {
                            display: block;
                        }
                    }
                }
            }

            .user-name {
                display: none;
            }
        }
    }

    .mobile-view {
        position: fixed;
        top: 4vh;
        width: 100vw;
        justify-content: center;
        display: inline-flex;

        img {
            cursor: pointer;
            outline: none;

            &:hover {
                filter: $menu-item-icon-hover;
            }
        }
    }
}

.hamburger {
    padding: 15px 10px;
    color: white;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        color: $action;
    }
}

.sidebar {
    width: 25%;
}

.app-background-creme,
.explore-color {
    &:not(.bookings-color):not(.activities-color) {
        .navbar {
            .navbar-header {
                .text-white {
                    color: $black !important;
                }
            }

            .navbar-nav {
                .nav-item {
                    a {
                        color: $black;

                        &:hover {
                            color: $menu-item-hover;
                        }
                    }
                }
            }

            .user-name {
                color: $black !important;
            }
        }

        .hamburger {
            color: $black;

            &:hover {
                color: $action;
            }
        }
    }
}
