@import "../../../styles/variables";

.landing-page {
    .space {
        margin-top: 10%;
    }

    &.show-results {
        .header-font-43 {
            margin-top: unset;
        }
    }

    .box-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 6px;
    }

    .box-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 1em;
        //margin: -35px 10% 0 10%;
        flex: 0 0 auto;
    }

    .w-42 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 42%;
        margin-top: 3%;
    }

    .w-52 {
        width: 52%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .map-container {
        width: 80%;
        height: 100%;
        margin-top: -35px;
    }
}

/**
  Conditional styles based on the activity type
   */

.app-background-black {
    .map-container {
        background-color: $gray;
    }
}

.app-background-blue {
    .map-container {
        background-color: $blue;
    }
}

.app-background-green {
    .map-container {
        background-color: $green-accent;
    }
}

.app-background-orange {
    .map-container {
        background-color: $red-accent;
    }
}

.app-background-creme {
    .map-container {
        background-color: $creme;
    }
}

/*
 Tablet specific styles
*/
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .landing-page {
        .box-row {
            margin: 0 5%;
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .landing-page {
        .box-row {
            height: 200vh;
            margin: 0;
        }
    }
}
