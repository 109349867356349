@import "../../../styles/common";

@mixin set-shadow() {
    opacity: 5;
    transition: 0.4s;
    box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.activities-container {
    padding: 0;

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .button-container {
        margin: 16px 0;
    }

    .swiper-container {
        .swiper-slide {
            img {
                width: 100%;
                height: 100%;
            }
        }

        .swiper-slide-active {
            @include color-zoom-in();
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            @include gray-zoom-out();
        }

        .swiper-button-prev,
        .swiper-button-next {
            color: white;
        }
    }

    .activity-info {
        overflow-y: hidden;
        min-height: 80vh;
        margin-top: -35px;
        background-color: $neutral3-hover;

        .col-6 {
            width: 60vw;
        }

        .col-4 {
            width: 40vw;
        }

        .icons-bar {
            @include font-style-template("Barlow-Regular", 16px, $neutral1);
            text-align: left;
            font-weight: lighter;
            line-height: 25px;
            margin-left: 5%;

            .desc-icons {
                height: 7vh;
                margin-top: 30px;

                img {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    filter: invert(100%) sepia(51%) saturate(410%) hue-rotate(291deg) brightness(81%) contrast(82%); //#bababa

                    &:hover {
                        filter: invert(64%) sepia(1%) saturate(0%) hue-rotate(341deg) brightness(89%) contrast(88%); //#8f8f8f
                    }

                    &:last-child {
                        margin-left: 1vw;
                    }
                }
            }

            h1 {
                @include font-style-template("Barlow-Bold", 20px, $neutral1);
                text-transform: uppercase;
            }

            .desc {
                height: 55vh;
                overflow-y: scroll;
            }

            hr {
                margin-top: 10px;
                margin-bottom: 15px;
            }

            .btn-rounded {
                @include button-template(10em, 200px, $normal-button-color, $neutral2, $normal-button-color-hover);
            }

            .btn-orange-rounded {
                margin-bottom: 0px;
            }
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .image-list::-webkit-scrollbar {
        display: none;
    }

    .image-list {
        margin-top: -2vh;
        margin-left: 0;
        width: 100%;
        height: 80vh;
        overflow-y: scroll;

        .act-info {
            position: relative;
            width: 20%;
            height: 50%;

            .image {
                width: 100%;
                height: 100%;
                position: relative;
                @include fit-image;

                &:hover {
                    filter: brightness(0.5);
                    transform: scale(1.02);
                }
            }

            span {
                opacity: 0;
                transition: 0.4s ease-in-out;
                pointer-events: none;

                h2 {
                    @include font-style-template("Barlow-Bold", 18px, $white);
                    text-transform: uppercase;
                    position: absolute;
                    bottom: 40px;
                    left: 20px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                }

                h3 {
                    text-align: left;
                    padding-top: 5px;
                    font-style: normal;
                    font-size: 16px;
                    text-transform: uppercase;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                    position: absolute;
                    bottom: 15px;
                    left: 20px;
                }
            }

            &:hover {
                cursor: pointer;

                span {
                    opacity: 1;
                }
            }
        }
    }
    .row {
        --bs-gutter-x: 0;
        margin-left: 0px;
        margin-right: 0px;
    }

    .buttons {
        // z-index: 99;
        height: 6.5vh;
        width: 100vw;
        box-shadow: -2px -14px 62px 0px rgba(0, 0, 0, 0.9);

        div {
            height: 100%;
            width: 20vw;
            padding-top: 2.5vh;
            text-transform: uppercase;
            text-align: center;

            @include set-shadow();
            @include font-style-template("Barlow-Bold", 20px, $neutral2);

            &:hover {
                cursor: pointer;

                h1 {
                    color: $action;
                }
            }
        }

        .black {
            p {
                padding-left: 20%;
            }

            h1 {
                padding-left: 5%;
                margin-top: 0%;
            }
        }
    }

    .filter {
        @include set-bg-color($neutral3-hover, $milky);
        @include set-shadow();
    }

    .black {
        @include set-bg-color($black, #8e4336);
        @include set-shadow();
    }

    .water {
        @include set-bg-color($blue-accent, #5d7785);
        @include set-shadow();
    }

    .earth {
        @include set-bg-color($green, #005f56);
        @include set-shadow();
    }

    .alpine {
        @include set-bg-color($red, #8e4336);
        @include set-shadow();
    }

    .air {
        @include set-bg-color($creme, #b39e7f);
        @include set-shadow();
    }
}

/*
 Tablet specific styles
*/
@media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
    .activities-container {
        .image-list {
            .act-info {
                width: 33.33%;
                height: 41%;
                span {
                    opacity: 1;
                }
            }
        }

        .buttons {
            h1,
            p {
                font-size: 10px;
            }
        }
    }
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .activities-container {
        .image-list {
            height: 75vh;
            .act-info {
                width: 50%;
                height: 41%;
                .image {
                    span {
                        opacity: 1;
                    }
                }
            }
        }

        .buttons {
            h1,
            p {
                font-size: 10px;
            }
        }
    }
}
