@import "../../../styles/common";

.host-offers-container {
    padding-bottom: 24px;

    .host-offers-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 1em;
        margin: 5% 10% 5% 10%;
        flex: 0 0 auto;
        box-shadow: -1px -1px 32px 12px rgba(0, 0, 0, 0.34);
    }

    .left-panel {
        position: relative;
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
        width: 30%;
        height: 100%;
        background: $neutral3;

        .saved-offers {
            position: relative;
            height: 100%;
            box-shadow: 2px -19px 39px -9px rgba(0, 0, 0, 0.34);
            text-transform: uppercase;
            font-family: "Barlow-Regular";
            color: $black;
            font-size: 14px;

            h2 {
                @include font-style-template("Barlow-Bold", 24px, $light-gray);
                padding-top: 2rem;
                padding-bottom: 2rem;
                text-align: center;
                height: 10%;
                text-align: center;
            }
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        .offer-template-list::-webkit-scrollbar {
            display: none;
        }

        .offer-template-list {
            position: relative;
            height: 85%;
            width: 100%;
            overflow-y: scroll;

            .offer-template {
                display: inline-block;
                width: 48%;
                text-align: center;
                background: whitesmoke;
                margin: 1%;

                &:hover {
                    cursor: pointer;
                    background: $yellow-button-color;

                    img {
                        filter: invert(1);
                    }

                    h1,
                    h3 {
                        color: white;
                    }
                }

                img {
                    width: 80px;
                    margin: 18px 0;
                    filter: $offer-template-icon;
                }

                h1 {
                    @include font-style-template("Barlow-Regular", 16px, $neutral1);
                    text-transform: uppercase;
                    font-weight: bolder;
                    margin-top: unset;
                    margin-bottom: 2px;
                }

                h3 {
                    @include font-style-template("Barlow-Regular", 14px, $neutral1);
                    text-transform: uppercase;
                    padding-bottom: 8px;
                }

                &.selected {
                    animation: blinking-selected-offer-template 5s infinite;
                    @keyframes blinking-selected-offer-template {
                        0% {
                            background: #a0733a;
                        }
                        25% {
                            background: #bc8745;
                        }
                        50% {
                            background: #ae7d3f;
                        }
                        75% {
                            background: #a0733a;
                        }
                        100% {
                            background: #a0733a;
                        }
                    }

                    background: $yellow-button-color;

                    img {
                        filter: invert(1);
                    }

                    h1,
                    h3 {
                        color: $white;
                    }
                }
            }
        }
    }

    .middle-panel {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-transform: uppercase;
        background-color: $host-bg-color;
        border-bottom-right-radius: 1em;
        border-top-right-radius: 1em;

        .nav-tabs {
            height: 12%;
            border-bottom: none;
            background-color: $host-dark-color;
            flex: 0;
            border-top-right-radius: 1em;

            .empty-nav-space {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                padding-right: 8px;
                flex: 1;

                .btn-orange-rounded {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    img {
                        width: 22px;
                        height: 22px;
                        filter: invert(1);
                    }
                }
            }

            .nav-item {
                width: 20%;

                .nav-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    @include font-style-template("Barlow-Bold", 24px, $neutral2);
                    line-height: unset;
                    padding: 1.5rem;
                    text-align: center;
                    border: 1px solid transparent;
                    border-top-left-radius: 0rem;
                    border-top-right-radius: 0rem;
                    box-shadow: inset -88px 145px 102px -131px rgba(0, 0, 0, 0.22);

                    &:hover {
                        background-color: $yellow-button-color;
                    }
                }
            }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: $host-bg-color;
        }

        .tab-content {
            overflow-y: auto;
            //background-color: $host-bg-color;

            .tab-pane {
                width: 100%;
                min-height: 98%;
                //background-color: $host-bg-color;
                border-bottom-right-radius: 1rem;

                h1 {
                    @include font-style-template("Barlow-Regular", 18px, $white);
                    font-weight: bold;
                }

                h2 {
                    @include font-style-template("Barlow-Regular", 14px, $host-dark-color);
                    font-weight: bold;
                }

                h3 {
                    @include font-style-template("Barlow-Regular", 13px, $neutral2);
                    text-align: right;
                }

                .switch-label {
                    @include font-style-template("Barlow-Regular", 13px, $host-dark-color);
                    margin-top: unset;
                }
                .new-edit {
                    padding: 24px;
                    height: 100%;
                    @include labelFieldTemplate();

                    input,
                    textarea,
                    select,
                    hr {
                        margin-bottom: 8px;

                        &.margin-top {
                            margin-top: 8px;
                        }
                    }

                    hr {
                        background-color: #905e32;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
    /*
     Desktop widths
  */
    @media screen and (min-width: $screen-lg) {
        .host-offers-content {
            max-width: 90%;
            height: 820px;
        }
    }

    /*
 Tablet specific styles
*/
    @media screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
        .host-offers-content {
            margin: 0 5%;
        }
    }

    /**
Phone specific styles
 */
    @media screen and (max-width: ($screen-md - 1)) {
        .host-offers-content {
            display: flow;
            flex-direction: column;
            margin: 0;
            height: 70vh;

            .left-panel::-webkit-scrollbar {
                display: none;
            }

            .left-panel {
                width: 100%;
                overflow: auto;
                border-top-left-radius: 1em;
                border-top-right-radius: 1em;
                border-bottom-left-radius: 0em;
            }

            .middle-panel {
                background-color: $host-dark-color;

                .nav-tabs {
                    height: 10%;

                    .nav-item {
                        .nav-link {
                            padding-top: 1rem;
                            padding-bottom: 0rem;
                            font-size: 10px;
                        }
                    }
                }

                .tab-content {
                    .tab-pane {
                        .new-edit {
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}
