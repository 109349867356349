@import "../../../styles/common";
.offer-details {
    width: 400px;
    .title {
        color: white;
        h2 {
            color: $white;
            padding-left: 8px;
        }
        h3 {
            text-transform: none;
            padding-left: 8px;
        }
    }
    .overview {
        color: white;
        border-radius: 1em;
        width: 100%;
        .flex-row {
            margin: 8px 0;
        }

        hr {
            background-color: $neutral2;
            margin: 4px 0;
        }

        h1 {
            @include font-style-template("Barlow-Regular", 12px, $neutral2);
            margin: 0;
        }
        h2 {
            @include font-style-template("Barlow-Regular", 12px, $neutral2);
            text-transform: unset;
            margin: 0;
        }
    }
    .buttons {
        margin: 8px 0;
        button:first-of-type {
            margin-right: 8px;
        }
    }
}
