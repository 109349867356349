.step--circle.active {
    background-color: #ec7523;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    animation: colorize-and-stretch 0.5s ease-in-out;
}

.step--circle.disabled {
    pointer-events: auto;
    opacity: 1;
    cursor: not-allowed;
}

.step--circle {
    cursor: pointer;
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    transform: scale(1);
}

.stepper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    margin: 1rem;
    min-height: calc(100vh - 15rem);
    width: 20px;
    border-left: 1px solid white;

    div {
        margin-left: -10px;
    }
}

@keyframes colorize-and-stretch {
    0% {
        background-color: #fff;
        transform: scale(1);
    }
    25% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        background-color: #ec7523;
    }
}
