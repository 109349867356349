@import "../../../styles/common";

.search-list-container {
    padding: 0 8px;
    div {
        margin-bottom: 10px;

        img {
            width: 40px;
            height: 40px;
            filter: invert(1);
            margin-left: auto;
        }

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $light-gray;
            border: none;
        }

        ul {
            height: 50vh;
            overflow-y: auto;
            margin: 0;
            padding: 0;
            li {
                list-style-type: unset;
                font-size: 16px;
                color: #fff;
                padding: 8px;
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }

        .host-content {
            height: 50vh;
            overflow-y: scroll;
            outline: none;
        }
    }
    h2 {
        font-size: 20px;
    }

    h3 {
        @include font-style-template("Barlow-Regular", 11px, $neutral2);
    }
}
