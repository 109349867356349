@import "../../../styles/common";

.marker-title {
    color: $black;
    text-transform: uppercase;
    font-size: 16px;
}

.marker-reviews {
    color: $black;
    text-transform: uppercase;
    font-size: 10px;
}

.mapboxgl-map {
    height: 90% !important;
    width: 100% !important;
}

/**
Phone specific styles
 */
@media screen and (max-width: ($screen-md - 1)) {
    .mapboxgl-map {
        height: 800px !important;
    }
}
