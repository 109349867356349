@import "../../../styles/common";

.host-management-container {
    margin-top: 6%;
    padding-bottom: 24px;

    .host-management-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $gray;
        border-radius: 1em;
        margin: -32px 10% 5% 10%;
        flex: 0 0 auto;
        box-shadow: -1px -1px 32px 12px rgba(0, 0, 0, 0.34);

        .host-management-header {
            height: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                @include font-style-template("Barlow-Bold", 24px, $neutral2);
                line-height: 3rem;
                margin: 8px 0 0 0;
            }
            h3 {
                @include font-style-template("Barlow-Bold", 16px, $neutral3);
                //line-height: 1rem;
                margin: 0 0 8px 0;
            }
        }
        .host-management-info {
            table {
                margin: 8px 0;
                width: 100%;
                color: $neutral2;
                td,
                th {
                    padding: 2px 8px;
                }
                thead {
                    tr {
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }
                tbody {
                    tr {
                        &:hover:not(.pagination) {
                            background-color: rgba(255, 255, 255, 0.2);
                            cursor: pointer;
                        }
                    }
                }
                tfoot {
                    span {
                        padding: 0 8px;
                    }
                    button {
                        font-family: serif;
                        border: 0;
                        font-size: 24px;
                        margin: 0 2px;
                        background-color: $medium-gray;
                        color: $neutral2;
                        &:disabled {
                            opacity: 0.2;
                        }
                    }
                }
            }
        }
    }
}
.host-management-details {
    .general-info-form {
        @include labelFieldTemplate($gray);
        position: relative;

        input,
        textarea,
        hr {
            margin-bottom: 8px;

            &.margin-top {
                margin-top: 8px;
            }
        }

        .MuiSwitch-switchBase {
            &.Mui-checked {
                color: $action;
                & + .MuiSwitch-track {
                    background-color: $action;
                }
            }
        }

        .switch-label {
            font-size: 14px;
            color: $neutral2;
        }

        .last-column-header {
            padding-right: 16px;
        }

        .flex-table {
            display: flex;
            flex-direction: column;

            .flex-header {
                color: $gray;
                font-size: 14px;
                font-weight: 300;
                padding-bottom: 8px;
            }

            .flex-row {
                color: $gray;

                &:not(:last-of-type) {
                    border-bottom: 1px dashed $gray;
                }
            }
        }
        .save {
            .btn-cancel-rounded {
                @include button-template(10em, 200px, $gray, $neutral2, darken($gray, 5%));
                margin-bottom: 10px;
            }
            .btn-delete-rounded {
                @include button-template(10em, 200px, darken($error-text, 15%), $neutral2, darken($error-text, 20%));
                margin-bottom: 10px;
            }
            button:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }
}
